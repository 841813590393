<template>
  <div class="login">
    <div class="main">
      <h3>手机快捷登录</h3>
      <van-form>
        <div class="ptr">
          <van-field clearable @blur="telVerify" label="手机号" :maxlength="11"  label-width="70px" placeholder="请输入手机号" v-model="telText" type="tel"
                     :rules="[{required:true,message:' ',validator:telVerify}]"
          >
          </van-field>
        </div>
        <div class="ptr">
          <div class="flexbt">
            <van-field clearable class="msgCode" @blur="smsVerify" label-width="70px" :maxlength="6"
                       :rules="[{required:true,message:'  ',validator:smsVerify}]"
                       label="验证码" placeholder="请输入验证码" v-model="smsText"/>
            <van-button  class="msgBtn" @click="getSms()" :disabled="smsDisabled" :class="{active: isActive}" >{{smsTime < 0 ? (smsFlag? '重新发送':
              '发送验证码'):
              "重新发送 " + smsTime + " s"}}</van-button>

          </div>
        </div>


      </van-form>
      <p class="errorMsg" v-if="phoneError">请输入正确的手机号</p>
      <p class="errorMsg" v-if="!phoneError && smsError">请输入正确的验证码</p>


    </div>
    <div class="footer">
      <van-button class="loginBtn" slot="button" size="small" @click="nextPage">登录</van-button>
      <div class="agreement">登录即代表您同意并接受<span @click="protocolClick('AGT008')">《用户注册协议》</span><span @click="protocolClick('AGT009')">《隐私政策》</span></div>
    </div>
  </div>
</template>


<script>
import common from '@/utils/common'
import { Field, Button, Icon, Toast } from 'vant'
import { getSmsMessage, checkSmsMessage, getAgreementNoToken } from '@/utils/api'
import {getDictByPage} from "../../utils/api";
import MobileDetect from 'mobile-detect'
import {uwStatisticAction} from "../../utils/util";
export default {
  name: "login",
  components:{
    [Field.name]: Field,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Toast.name]: Toast
  },
  watch:{
    telText(newValue, oldValue){
      if(newValue){
        this.phonecrossShow = true
      }
      if(newValue.length == 0){
        this.phonecrossShow = false
      }
      // if (newValue.length > oldValue.length) {
      //   if (newValue.length === 4 || newValue.length === 9) {
      //     var pre = newValue.substring(0, newValue.length - 1);
      //     var last = newValue.substr(newValue.length - 1, 1);
      //     this.telText = pre + ' ' + last;
      //   } else {
      //     this.telText = newValue;
      //   }
      // } else {
      //   if (newValue.length === 9 || newValue.length === 4) {
      //     this.telText = this.telText.trim();
      //   } else {
      //     this.telText = newValue;
      //   }
      // }
    },
    smsText(newValue, oldValue){
      if(newValue){
        this.smscrossShow = true
      }
      if(newValue.length == 0){
        this.smscrossShow = false
      }
    },
  },
  data() {
    return {
      telText: "",
      smsText: "",
      smsFlag: false,
      smsCode: false,
      smsTime: -1,
      timer: null,
      smsDisabled: false,
      isActive: false,
      isDisabled: false,
      IP: {},
      LBS: {},
      NETTYPE: "",
      startTime:'',
      pointCount:0,
      phonecrossShow: false,
      smscrossShow: false,
      phoneError: false,
      smsError: false,
      md: new MobileDetect(window.navigator.userAgent),
    }
  },
  methods: {
    clearPhone() {
      this.telText = ''
      this.phoneError = false
    },
    clearSms() {
      this.smsText = ''
      this.smsError = false
    },
    // 手机号失去焦点

    telVerify(val) {
      common.kickBack();
      this.phoneError = !common.phoneVerify(this.telText.replace(/\s+/g,""))
      return  !this.phoneError
    },
    // 短信验证码失去焦点
    smsVerify() {
      this.smsError = !common.numberVerify(this.smsText)
     return !this.smsError
    },
    // 短信倒计时
    smsCountDown() {
      this.smsTime = 60;
      this.isActive = true;
      this.smsDisabled = true;
      this.timer = setInterval(() => {
        this.smsTime--;
        if (this.smsTime < 0) {
          this.isActive = false;
          this.smsDisabled = false;
          clearInterval(this.timer);
        } else {
          this.isActive = true;
          this.smsDisabled = true;
        }
      }, 1000);
    },
    // 获取短信验证码
    getSms() {
      console.log("hhhh")
      if (!this.telText.replace(/\s+/g,"")) {
        this.phoneError = true
        return
      }
      if (!common.phoneVerify(this.telText.replace(/\s+/g,""))) {
        this.phoneError = true
        return
      }
      console.log("拉拉卡拉")
      getSmsMessage({
        phoneNo: this.telText.replace(/\s+/g,""),
        msgType: 'code.h5.login'
      }).then(res=>{
        if(res.code === 200){
          // let data = JSON.parse(common.decrypt(res.data, res.timestamp))
          if(res.data.businessCode && res.data.businessCode === 200){
            this.phoneError = false
            this.smsFlag = true
            this.smsCode = true
            this.smsCountDown()
            Toast(res.data.businessMessage)
          }
        }
      })
    },
    // 校验短信验证码
    checkSms() {
      if (!this.smsCode) {
        return
      }
      checkSmsMessage({
        phoneNo: this.telText.replace(/\s+/g,""),
        msgType: 'code.h5.login',
        captch: this.smsText,
        loginAgreementInfo: "{'ag_001':'v1.0.1','ag_002':'v1.1.1'}",
        phoneModel: JSON.stringify(this.md.phone())
      }).then(res=>{
        if(res.code === 200){
          // let data = JSON.parse(common.decrypt(res.data, res.timestamp))
          if(res.data.businessCode && res.data.businessCode === 200){
            common.setItem('wtToken', res.data.token)
            this.$store.dispatch('permission/setPermissionAction')
            this.$store.dispatch('keepAliveState/removeKeepAlive', 'login')
            this.getCustomerPageInfo()
            this.getAccountPageInfo()
            common.setItem('phoneNo', this.telText.replace(/\s+/g,""))
            common.setItem('userName', res.data.userName)
            common.setItem('userRole', res.data.userRole) // 用户角色 0：市场人员 1：站长
            common.setItem('uniqueNo', res.data.uniqueNo)
            common.setItem('isAudit', res.data.isAudit) // 是否是审批人员 0：否 1：是
            common.setItem('authStep', res.data.authStep) // 判断第几步
            if(res.data.userRole == 1){
              common.setItem('masterNo', res.data.uniqueNo)
              common.setItem('stationNo', res.data.stationNo)
              if(this.$route.query.redirect){
                this.$router.push({name: this.$route.query.redirect, query:{role: res.data.userRole}})
                if(res.data.authStep == 'needReal'){
                  common.setItem('noRealName', res.data.userName) // 未实名
                }
                return
              }
              //站长认证步骤： needReal：需身份证认证； needBankVerify：需银行卡认证； needBizLicense：需营业执照；needSign:需签约; signed：已提交签约； （仅用户角色为站长时返回）
              if(res.data.authStep == 'needReal'){
                this.$store.dispatch('keepAliveState/addKeepAliveAction', 'idcardProving')
                this.$router.push({name: 'idcardProving'})
                common.setItem('noRealName', res.data.userName) // 未实名
              }
              if(res.data.authStep == 'needBizLicense'){
                this.$router.push({name: 'businessLicense'})
              }
              if(res.data.authStep == 'needBankVerify'){
                this.$store.dispatch('keepAliveState/addKeepAliveAction', 'cardInfo')
                this.$router.push({name: 'cardInfo'})
              }
              if(res.data.authStep == 'needSign'){
                // this.$router.push({path:'/stationagent/waitSign'})
                this.$router.push({name:'homePage'})
              }
              if(res.data.authStep == 'signed'){
                this.$router.push({name:'homePage'})
              }
              return
            }
            if(this.$route.query.redirect){
              this.$router.push({name: this.$route.query.redirect, query:{role: res.data.userRole}})
            }
            else if (this.$route.query.back001 === '1'){
              this.$router.go(-1)
            }
            else{
              this.$router.push({name: 'homePage'})
            }
            if (parseInt(res.data.userRole) === 0){
              common.setItem('roleName', '运营人员')
            }else  if (parseInt(res.data.userRole) === 1){
              common.setItem('roleName', '站长')
            }else  if (parseInt(res.data.userRole) === 2){
              common.setItem('roleName', '游客')
            }else  if (parseInt(res.data.userRole) === 3){
              common.setItem('roleName', '部门负责人')
            }else  if (parseInt(res.data.userRole) === 4){
              common.setItem('roleName', '高级用户')
            }
          }
        }
      })
    },
    getAccountPageInfo () {
      if (common.getItem('wtToken') == undefined){
        return
      }
      getDictByPage('accountPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            common.setItem('tranType',JSON.stringify(JSON.parse(data.data).tranType))
          }
        }
      })
    },
    getCustomerPageInfo () {
      if (common.getItem('wtToken') == undefined){
        return
      }
      getDictByPage('customerPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            common.setItem('customerGroup',JSON.stringify(JSON.parse(data.data).customerGroup))
          }
        }
      })
    },
    // 点击下一步
    nextPage() {
      this.pointCount++;
      if (!this.telText.replace(/\s+/g,"")) {
        this.phoneError = true
        return
      }
      if (!common.phoneVerify(this.telText.replace(/\s+/g,""))) {
        this.phoneError = true
        return
      }
      if (!this.smsText) {
        this.smsError = true
        return
      }
      if (!this.smsFlag) {
        Toast('请点击发送短信验证码')
        return
      }
      if (!common.numberVerify(this.smsText)) {
        this.smsError = true
        return
      }
      this.checkSms()
    },
    protocolClick(agreementNo) {
      getAgreementNoToken({
        agreementNo
      }).then(res=>{
        if(res.code === 200){
          if(res.data && res.data.businessCode === 200){
            if(agreementNo == 'AGT008'){
              this.$router.push({name: 'registerProtocol'})
            }else{
              this.$router.push(res.data.data.agreementPath)
            }
          }
        }
      })
    },
  },
  mounted() {
    localStorage.removeItem('wtToken')
    localStorage.removeItem('phoneNo')
    localStorage.removeItem('userName')
    localStorage.removeItem('userRole')
    localStorage.removeItem('uniqueNo')
    localStorage.removeItem('isAudit')
    localStorage.removeItem('signZZ')
    localStorage.removeItem('authStep')
    localStorage.removeItem('noRealName')
    localStorage.removeItem('masterNo')
    localStorage.removeItem('jumpFrom')
    localStorage.removeItem('newsHomeScrollTop')
    this.$store.dispatch('keepAliveState/addKeepAliveAction', 'login')
    uwStatisticAction('/login','登录')
  }
}
</script>

<style scoped lang="less">
.login {
  height: 100%;
  font-size: 0.3rem;
  .ptr{
    border-bottom: 1px solid #eee;
  }
  .flexbt{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    padding-top: 1rem;
    position: relative;
    h3{
      font-size:27px;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      text-align: center;
      margin: 8px 0  38px 0;
    }
    .msgCode{
      width: calc(100% - 140px);
    }
    .errorMsg{
      color: #f4664a;
      font-size: 12px;
      position: absolute;
      bottom: -17px;
      left: 12px;

    }
    .msgBtn{
      border: none;
      border-left: 1px solid #eee;
      font-size:17px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color: #3F7C53;
      width: 180px;
      height: 40px;
      text-align: right;
      line-height: 40px;
    }
    .inp {
      position: relative;
      .phone, .sms{
        margin: 0.1rem 0.2rem;
      }
      .sms{
        margin-top: 0.4rem;
      }
      input {
        width: 100%;
        height: 1rem;
        font-size: 0.3rem;
        background-color: #fff;
        padding-left: 0.2rem;
        border-bottom: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        position: relative;
      }
      .phone-cross{
        position: absolute;
        right: 0.3rem;
        top: 58%;
      }
      .sms-cross{
        position: absolute;
        right: 2.3rem;
        top: 58%;
      }
      button {
        width: 2.2rem;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.3rem;
        color: #3F7C53;
        background: #fff;
        text-align: center;
        position: absolute;
        right: 0;
        top: 48%;
      }
    }
    .error-msg{
      color: #ee0a24;
      padding-left: 86px;
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      position: absolute;
      left: 0;
      bottom: 0px;
    }
  }
  .agreement {
    font-size: 12px;
    padding-left: 12px;
    text-align: left;
    margin-top: 10px;
    color:#999999;
    span {
      color: #3F7C53;
    }

  }
  .footer{
    text-align: center;
    margin-top: 40px;
    .loginBtn{
      background: #3F7C53;
      height: 49px;
      line-height: 49px;
      border-radius: 4px;
      width: calc(100% - 24px);
      margin: 0 auto;
      font-size:18px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color: #ffffff;
    }
    button{
      width: 4rem;
      height: 0.8rem;
      font-size: 0.32rem;
      border-radius: 0.4rem;
    }
  }
}
</style>
<style>
  .login .van-cell{
    padding: 12px;
  }
  .login .van-cell:not(:last-child)::after{
    opacity: 0;
  }

  .loginBtn .van-field__control ,.login .van-field__label,.login  .van-field__value{
    font-size: 17px;
  }
  .login .van-field__error-message{
    margin-top: -3px;
  }
  .login .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
    color: #999;
  }
</style>
